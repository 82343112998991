@import '~@styles/variables.scss';

.statistic-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  box-shadow: $card-small-shadow;
  margin: 15px;
  border-radius: 5px;
  padding: 15px 8px;
  background: $color-white;

  &-clickable {
    &:hover {
      background: $color-grey-99;
    }
  }

  &-mobile {
    flex: 1 1 100%;
  }

  &__circle-wrapper {
    position: relative;
    display: flex;
    align-self: stretch;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__circle-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-grey-50;
    font-weight: 700;
    font-size: $font-size-sm;
  }

  &__title {
    margin: auto;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: $font-size-m;
    color: black;
    line-height: 1.5;
    text-align: center;
  }
}
