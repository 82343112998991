@import '~@styles/variables.scss';

.employee-card,
.employee-card__loader,
.employee-card__loader--expanded {
  width: 100%;
  padding: 20px;
  background: $color-white;
  color: $color-grey-10;
  font-size: 13px;
  line-height: 1.5;
  white-space: break-word;

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $color-hover;
      .person-avatar {
        box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
      }
    }
  }

  .person-avatar__initials {
    font-size: $font-size-m !important;
  }

  &--centered {
    align-items: center;
  }

  &--expanded {
    align-items: center;
    background: white;
    color: inherit;
    font-size: 14px;

    &__top-section {
      margin-bottom: 10px;
    }

    .employee-card__name {
      margin-bottom: 4px;
      line-height: 1.3;
      text-align: center;
    }
  }

  &__top-section {
    display: flex;
    flex: 1 0 0;

    &--vertical {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }
  }



  &__chart {
    position: absolute;
  }

  &__avatar {
    $avatar-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-size;
    height: $avatar-size;
    border: none;
    border-radius: 50%;
    padding: 0;
    margin-left: 10px;
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-main-dark);
    font-size: $font-size-m;
    &--list {
      position: absolute;
      left: -8px;
    }
    &--centered {
      $centered-avatar-size: 100px;

      width: $centered-avatar-size;
      height: $centered-avatar-size;
      border-radius: 50%;
      color: var(--color-main-dark);
      font-size: $font-size-m;
    }

    i {
      transform: scale(1.1);
    }
  }

  &__name-wrapper {
    justify-content: center;
    width: 25%;
    margin-left: 20px;
    color: $color-grey-10;
    text-overflow: ellipsis;
    white-space: normal;

    &--centered {
      align-items: center;
      margin-top: 15px;
      margin-left: 0;
      color: var(--color-main-dark);
    }
  }

  &__name {
    overflow-wrap: break-word;
    font-weight: 500;
    font-size: $font-size-m;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal;
    hyphens: auto;
  }

  &__position {
    overflow-wrap: break-word;
    font-weight: 400;
    font-size: $font-size-sm;
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto;
  }

  &__phone {
    overflow-wrap: break-word;
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto;

    a {
      color: $font-color;
      text-decoration: none;
    }
  }

  &__email {
    overflow-wrap: break-word;
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto;

    a {
      color: $font-color;
      text-decoration: none;
    }
  }

  &__progress-wrapper {
    width: 100%;
    margin: 20px 0;
    margin-bottom: 13px;
    padding: 0 15px;

    .progress__bar-complete {
      height: 7px;
      border-radius: 3px;
      background-color: $color-progressbar-fill-2;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
    width: 140px;
    margin-top: 7px;
  }

  &__button {
    width: 45px;
    height: 45px;
    box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
    border: none;
    border-radius: 30px;
    background: e;
    color: $color-white;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
  }

  @media only screen and (max-width: $tablet-width) {
    padding: 10px;
  }

  &--mobile {
    float: none !important;
    flex: 1 1 auto;
    flex-direction: row;
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    min-height: 0 !important;
    box-shadow: none;
    background: none;

    .employee-card__name {
      margin-bottom: 8px;
      font-size: $font-size-m;
    }

    .employee-card__progress-wrapper {
      margin: 0;
      padding: 0;
    }

    .employee-card__actions {
      position: relative;
      justify-content: normal !important;
      width: auto;
      margin-bottom: 5px;

      .btn--icon {
        margin-top: auto;
        margin-left: 10px;
      }

      .btn--expanded {
        border-radius: 0;
      }

      .btn__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;
        width: 250px;

        .task-list {
          box-shadow: none;

          &__item {
            border-radius: 0 !important;
            border-bottom: none;
            background: var(--color-main-dark);

            .employee-preview__add-menu-item {
              border-bottom: 1px solid $color-white;
              background-color: transparent !important;
              color: $color-white;
            }

            &:last-child {
              .employee-preview__add-menu-item {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.employee-card__loader {
  height: 90px;

  svg {
    flex: 0 0 auto;
    width: 225px;
    height: 50px;
  }

  &--expanded {
    display: flex;
    align-items: flex-start;
    height: 375px;
    padding: 0;

    svg {
      flex: 0 0 auto;
      width: 225px;
      height: 306px;
      margin: 0 auto;
      margin-top: 0;
    }
  }

  &--mobile {
    .employee-card__top-section {
      overflow: hidden;
      flex: 1 1 auto;
      width: 409px;

      svg {
        flex: 0 0 auto;
        width: 409px;
        height: 52px;
      }
    }

    .employee-card__actions {
      svg {
        flex: 0 0 auto;
        width: 150px;
        height: 40px;
      }
    }
  }
}
