@import '~@styles/variables.scss';

.chipses {
  $this: &;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 50px;
  padding: 0;

  &--mobile {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 18px;
    font-size: $font-size-m;

    #{$this} {
      &__container {
        justify-content: center;
      }

      &__chips {
        font-size: $font-size-sm;
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
  }

  &__chips {
    margin-right: 7px;
    margin-bottom: 7px;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 8px 14px;
    background: #fff;
    color: $color-grey-20;
    font-weight: 600;
    font-size: $font-size-sm;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: #000;
    }

    &--active {
      border: 1px solid #fff;
      background: var(--color-action);
      color: #fff;

      &:hover {
        border: 1px solid #fff;
        color: white;
      }
    }
  }
}
