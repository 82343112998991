@import '~@styles/variables.scss';

.my-education {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 10px 0;

  &__section-option {
    width: 100%;
    padding: 0;
  }

  .messages-list-wrapper {
    max-width: 1280px;
    margin: 0 auto;
  }

  .competences-list-wrapper {
    max-width: 1280px;
    margin: 0 auto;
  }

  .profile-top {
    width: 100%;
    height: 50px;
    margin-bottom: 70px;


    &__person-info {
      position: relative;
      max-width: 1024px;
      margin: 0 auto;
      padding-left: 20px;

      &__cv {
        margin-top: 24px;
        margin-left: 106px;
        button {
          float: left;
          margin-right: 10px;
          border: 1px solid #e7e7e7;
          border-radius: 40px;
          padding: 12px 14px;
          background-color: #fff;
          color: #000;
          font-size: $font-size-sm;
          &.active{
            background-color: var(--color-header-top);
            color: #FFF;
          }
          .badge {
            position: absolute;
            right: 8px;
            top: 10px;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            border-radius: 20px;
            background-color: #2f93e0;
            color: white;
            line-height: 20px;
            text-align: center;
          }

          &:hover {
            border: 1px solid #999;
            cursor: pointer;
          }
        }
        a {
          float: left;
          margin-right: 10px;
          border: 1px solid #e7e7e7;
          border-radius: 8px;
          padding: 12px 24px;
          background-color: #fff;
          color: #000;
          font-size: $font-size-sm;
          &.active{
            background-color: var(--color-header-top);
            color: #FFF;
          }
          .badge {
            float: right;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            border-radius: 20px;
            background-color: #2f93e0;
            color: white;
            line-height: 20px;
            text-align: center;
          }

          &:hover {
            border: 1px solid #999;
            cursor: pointer;
          }
        }
      }

      .person {
        margin-left: 110px;

        .person-avatar__image {
          border: 1px solid #bbb;
        }

        .person-avatar__initials {
          color: #bbb;
        }

        h2 {
          font-size: $font-size-m;
        }

        &__top-section {
          position: absolute;
          top: 10px;
          left: 20px;
        }

        &__education-status-container {
          position: relative;
          width: 250px;
          margin-top: 20px;
          color: #7f7f7f;
        }

        .person__title {
          position: absolute;
          top: 3px;
          left: 45px;
          font-size: $font-size-s;
        }
      }
    }

    @media screen and (max-width: $tablet-width) {
      display: none;
    }
  }

  &__section {
    width: 100%;
    margin: 15px 0 0;
    transition: flex 0.3s ease-out;

    &:last-child {
      margin-bottom: 0;

      @include lg() {
        margin-bottom: 100px;
      }
    }

    &__competences {
      max-width: 1024px;
      margin: 0 auto;
    }

    &__title {
      margin-top: 50px;
      text-transform: uppercase;

      h2 {
        font-weight: 500;
      }
    }

    &__option-courses {
      min-height: 200px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 20px;
      padding-top: 30px;
      background-color: #e8e8e8;

      @include lg() {
        padding: 0;
      }

      .content {
        max-width: 1024px;
        margin: 0 auto;

        h2 {
          margin-top: 10px;
          margin-bottom: 40px;
          font-size: $font-size-ml;
        }

        .img {
          min-height: 140px;
        }

        .my-education__section__role-wrapper__card {
          border-bottom: 0;
        }
      }
    }

    &__role-header {
      max-width: 1024px;
      margin: 0 auto;
      margin-bottom: 60px;
      font-size: $font-size-sm;
      h1 {
        margin-bottom: 0;
        color: black;
        font-size: $font-size-xl;
      }
      .circle {
        align-self: flex-end;
        width: 100%;
        font-size: 36px;
      }
    }

    &__role-required {
      max-width: 1024px;
      margin: 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      h2 {
        margin-bottom: 30px;
      }
      .header{
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 20px;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }

      .role-content {
        padding: 40px;
      }
    }

    &__role-passed {
      max-width: 1024px;
      margin: 0 auto;
      padding: 20px;

      @include lg() {
        padding: 0;
      }

      h2 {
        font-size: $font-size-ml;
        text-transform: uppercase;
      }
    }

    &__role-wrapper {
      h2 {
        font-size: $font-size-ml;
      }
      font-size: $font-size-s;
      a {
        h2 {
          color: black;
        }
      }
      .employees-checklist__card {
        box-shadow: none;
        background: none;
      }

      &__card {
        .content {
          padding-left: 10px;

          @include sm() {
            padding: 0;
          }
          margin-left: 20px;
          h2 {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            text-transform: inherit;
          }

          .desc {
            color: #333;
            font-size: $font-size-s;
            line-height: 1.2em;
          }

          .course-preview__actions {
            justify-content: left;
            margin-top: 30px;
          }
        }

        .sub.equals {
          h3 {
            border-bottom: 1px solid #ccc;
            font-size: $font-size-sm;
            line-height: 2rem;
          }

          .competence-card-sub {
            margin-bottom: 30px;
            font-size: $font-size-sm;

            .course-preview__actions {
              margin-top: 0;
            }
          }
        }

        .img {
          width: 95%;
          min-height: 180px;
          margin-right: 20px;
          border: 1px solid white;
          border-radius: 10px;
          background-position: center;
          background-size: cover;
        }

        margin-bottom: 30px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 50px;
      }

      &__card-small {
        a.__download-link {
          color: $color-blue;
          font-size: $font-size-sm;
        }

        .content {
          margin-top: 6px;
          margin-bottom: 1em;
          color: #666 !important;
          font-size: $font-size-s;

          h2 {
            width: 100%;
            min-height: 48px;
            margin-top: 0;
            margin-bottom: 0;
            font-size: $font-size-m;
            text-transform: inherit;
          }

          .__download-link {
            display: block;
            margin-top: 0.4em;
          }
        }

        .img {
          display: flex;
          width: 100%;
          height: 200px;
          margin-right: 20px;
          border: 4px solid white;
          border: 9px solid white;
          background-position: center;
          background-size: cover;
        }

        margin-bottom: 30px;
        padding: 20px;
      }
    }

    &__role-path {
      display: flex;
      box-shadow: 2px 2px 2px #ddd;
      margin-bottom: 30px;
      border-radius: 8px;
      padding: 20px;
      background-color: white;

      h1 {
        float: left;
        width: 60%;
        margin-top: 5px;
        margin-bottom: 0;
        font-size: $font-size-l;
      }

      .progress {
        margin-top: 0;
      }
    }
  }

  .cell.center-vertical {
    margin-top: auto;
    margin-bottom: auto;
  }

  .loading__image {
    min-height: 300px;
  }

  @media screen and (max-width: $tablet-width) {
    width: 100%;

    &__section {
      width: 100%;
      margin-top: 0;
    }
  }
}
