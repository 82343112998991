@import '~@styles/variables.scss';

.events-list {
  flex: 1 1 auto;
  padding: 20px 0;

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    border: 1px solid $event-border;
    border-radius: 10px;
    padding: 10px;
    color: $event-default-text-color;
    background-color: $event-default-background;
    a {
      color: $event-default-text-color;
    }
    &.event {
      background-color: $event-background;
      color: $event-text-color;
      a {
        color: $event-text-color;
      }
    }
    &.expire {
      color: $event-expire-text-color;
      background-color: $event-expire-background;
      a {
        color: $event-expire-text-color;
      }
      .events-list__card{
        border: 0;
      }
      .events-list__date{ border-color: $event-expire-text-color}
      .events-list__text__description {
        color: $event-expire-text-color;
      }
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    min-width: 50px;
    height: 100%;
    margin-right: 20px;
    border: 1px solid $event-text-color;
    border-radius: 6px;
    padding: 6px;
    line-height: 1.2;

    &__day {
      font-weight: 600;
      font-size: $font-size-m;
    }

    &__month {
      font-size: $font-size-s;
    }
  }

  &__avatar {
    flex: 0 0 40px;
    margin-top: 5px;
    margin-right: 25px;
    margin-left: 10px;

    .person-avatar {
      width: 45px;
      height: 45px;
    }
  }

  &__card {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    border: 1px solid #CCC;
    padding: 0.9em;
    padding-right: 1.5em;

    > a {
      width: 100%;
      color: black;
    }

    &__contents {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

    &.first {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.last {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &__text {
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1000px;
      left: -1000px;
      display: block;
      border-top: $separator-border;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;

      &::after {
        border-top: none;
      }
    }

    &__time_and_location {
      display: flex;
    }

    &__title {
      color: $event-text-color;
      font-weight: 600;
      font-size: $font-size-sm;

      &:hover {
        color: var(--color-main-dark);
        text-decoration: underline;
      }
    }

    &__description {
      font-size: $font-size-s;
      line-height: 1.4;
      color: $event-text-color;
    }

    &__location {
      font-size: $font-size-s;
    }
  }

  &__person-name {
    margin-bottom: 6px;
    color: $font-color;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
