@import '~@styles/variables.scss';

.breadcrumbs {
  width: 98%;
  max-width: 1280px;
  margin: 0;

  padding: 10px 0;
  color: #3b3b3b;
  font-weight: 400;
  font-size: $text-size-sm;
  line-height: 1.5;

  svg {
    margin-right: 16px;
    margin-bottom: 2px;
    margin-left: 16px;
    font-size: 10px;
  }

  a {
    color: $color-grey-700;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
  }

  a.active {
    color: $color-grey-600;
  }
}

.narrow {
  margin-top: 0;

  .breadcrumbs {
    max-width: 1024px;
  }
}
