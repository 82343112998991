@import '~@styles/variables.scss';
@import '~@routes/reports/variables.scss';

.competence-report {
  $this: &;

  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 40px 80px;

  @media screen and (max-width: $large-screen) {
    padding: 40px 20px;
  }

  @media screen and (max-width: $medium-screen) {
    padding: 40px 10px;
  }

  &__header {
    margin: 0;
    margin-bottom: 20px;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 40px;
  }

  &__top-section {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px;

    &__chart {
      position: relative;
      overflow: visible;
      flex: 1 1 auto;
      height: 280px;
      margin-right: 40px;
      margin-left: -80px;

      @media screen and (max-width: $large-screen) {
        margin-right: 10px;
        margin-left: -40px;
      }

      .extra-chart {
        overflow: visible;
        height: 100%;
      }
    }

    &__statistics {
      flex: 0 0 40%;

      > * {
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }

    @media screen and (max-width: $medium-screen) {
      flex-direction: column;

      &__chart {
        height: 200px;
        margin: 0;
        margin-bottom: 30px;
      }
    }
  }

  &__section-header {
    flex: 0 0 auto;
    align-self: baseline;
    margin-top: 20px;
    margin-right: auto;
    margin-bottom: 20px;
    border-bottom: 3px solid $color-points;
    padding: 5px 8px;
    color: $font-color-light;
    font-weight: 700;
    font-size: $font-size-xxl;

    &-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    box-shadow: $card-shadow;
    margin-top: 40px;
    margin-bottom: 60px;

    &__title {
      overflow: hidden;
      flex: 1 1 100%;
      width: 100%;
      border: $card-border;
      border-bottom: none;
      padding: 20px 40px;
      background: $color-white;
      color: $color-grey-40;
      font-weight: bold;
      font-size: $font-size-xl * 0.8;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__content {
      display: flex;
      flex: 1 1 100%;
      flex-direction: row;

      @media screen and (max-width: $medium-screen) {
        flex-direction: column;
      }
    }

    &__aside {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border: $card-border;
      background: $color-white;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        border-bottom: $card-border;
        padding: 0 20px;
      }

      :last-child {
        border-bottom: none;
      }

      @media screen and (max-width: $medium-screen) {
        position: relative;
        flex-direction: row;
        width: 100%;
        height: auto;
        border-top: none;

        :last-child {
          border-right: none;
        }

        &__item {
          height: 100%;
          margin: 0;
          border: none;
          border-right: $card-border;
          padding: 10px 0;
        }
      }
    }
  }

  &__chart {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 50px;

    &-container {
      position: relative;
      display: block;
      width: 100%;
      height: 450px;
      border: $card-border;
      border-right: none;
      background: $color-white;
    }

    @media screen and (max-width: $medium-screen) {
      margin: 20px;

      &-container {
        height: 400px;
        border-right: $card-border;
      }
    }
  }

  &__badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -20px;
    margin-bottom: 60px;

    @media screen and (max-width: $large-screen) {
      margin-right: -10px;
      margin-left: -10px;
    }

    @media screen and (max-width: $medium-screen) {
      flex-direction: column;
      flex-wrap: unset;
    }

    &__column {
      display: flex;
      flex: 0 1 50%;
      flex-direction: column;
    }
  }

  &__badge {
    overflow: hidden;
    flex: 1 1 auto;
    height: 90px;
    padding: 0 25px;

    &-wrapper {
      display: flex;
      flex: 0 0 auto;
      margin: 6px 0;
      padding: 0 20px;
    }

    @media screen and (max-width: $large-screen) {
      &-wrapper {
        padding: 0 10px;
      }
    }

    @media screen and (max-width: $medium-screen) {
      margin: 5px 0;
    }
  }

  @media screen and (max-width: $tablet-small-width) {
    &__badge {
      width: 100%;
    }
  }
}
