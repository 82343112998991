@import '~@styles/variables.scss';

.badge-card {
  display: flex;
  align-items: center;
  min-height: 100px;
  box-shadow: $card-shadow;
  margin: 10px 0;
  border: $card-border;
  padding: 10px 25px;
  background: $color-white;
  $icon-size: 65px;

  &__icon {
    overflow: visible;
    display: flex;
    flex: 0 0 $icon-size;
    justify-content: center;
    align-items: center;
    width: $icon-size;
    min-width: $icon-size;
    height: $icon-size;
    border-radius: 100%;

    &__text {
      font-weight: 800;
      font-size: $icon-size / 3.25;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 25px;
    color: $font-color-light;
  }

  &__title {
    font-weight: bold;
    font-size: $font-size-l;
  }

  &__text {
    font-size: $font-size-m;
  }
}
