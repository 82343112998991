@import '~@styles/variables.scss';

.person-form {
  display: flex;
  flex-wrap: wrap;

  &__input-wrapper {
    flex: 1 0 50%;
  }
  &__inputs-wrapper{
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: block;
    margin-top: 1em;
    padding-right: 5px;
    text-align: right;
    display: flex;
    margin-left: auto;
  }

  @media only screen and (max-width: $tablet-width) {
    justify-content: center;

    &__input-wrapper {
      flex: 0 0 100%;
    }

    &__submit-button-wrapper {
      width: auto;
    }
  }
}
